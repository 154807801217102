<template>
  <div class="player-list">
    <template v-if="tableData.length > 0">
      <li class="door-item" v-for="item in tableData" :key="item.deviceId">
        <p class="p-top">
          <span>{{ item.deviceName }}</span>
          <el-tag
              :type="item.deviceStatus ? 'success' : 'danger'"
              v-if="item.gatewayStatus == 1"
              >{{ deviceStatusObj[item.deviceStatus] }}</el-tag
            >
            <el-tag type="danger" v-else>网关离线</el-tag>
        </p>
        <P class="p-mid">位置：{{ item.deviceAddress }}</P>
        <p class="p-mid">外部地址：{{ item.deviceNumber }}</p>
        <div class="p-foot">
          <el-popover placement="right" width="200" trigger="click">
            <el-button plain type="primary" slot="reference">设备操作</el-button>
            <p class="opt-title">播放</p>
            <el-button class="opt-btn" v-for="r in radioObj" :key="r.value" type="primary" size="mini"
              @click="onRadio(item, r, 'PlayVoice')">{{ r.label
              }}</el-button>
            <p class="opt-title">音量</p>
            <el-button class="opt-btn" v-for="v in volumeObj" :key="v.value" type="primary" size="mini"
              @click="onRadio(item, v, 'SetDoorVolume')">{{ v.label
              }}</el-button>
          </el-popover>
        </div>
      </li>
    </template>
    <el-empty class="empty" v-else description="暂无设备信息"></el-empty>
  </div>
</template>

<script>
import { getDeviceList, getDeviceOperate } from "@/api/device/device";
import { deviceStatusObj, deviceStatusColor, radioObj, volumeObj } from "@/db/objs";
export default {
  data() {
    return {
      deviceStatusObj,
      deviceStatusColor,
      radioObj,
      volumeObj,
      tableData: [],
      tableParams: {
        page: 1,
        pageSize: 0,
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        deviceType: "12", //设备类型
        gatewayId: "", //网关
        deviceStatus: "", //设备状态
        bindStatus: "1", //绑定状态
      },
      value: true
    }
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getDeviceList();
  },
  methods: {
    // 【请求】设备列表
    getDeviceList() {
      let data = this.tableParams;
      getDeviceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
        }
      });
    },

    // 【请求】设备控制
    getDeviceOperate(data) {
      getDeviceOperate(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getDeviceList();
        }
      });
    },

    // 【监听】播放
    onRadio(item, r, command) {
      let data = {
        deviceId: item.subDeviceVoList[0].deviceId,
        volumePercentage: r.value,
        command,
      };
      this.getDeviceOperate(data);
    },
  }
}
</script>


<style lang="scss" scoped>
.player-list {
  display: flex;
  flex-wrap: wrap;
}

.door-item {
  background: #fff;
  display: inline-flex;
  flex-direction: column;
  margin: .15rem .15rem 0 0;
  border-radius: .1rem;
  width: calc((100% - .6rem)/ 5);
}

.door-item:nth-child(5n) {
  margin-right: 0;
}

p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p-top {
  padding: .15rem;
  margin-bottom: .15rem;
  border-bottom: 1px solid #f0f2f5;
}

.p-mid {
  color: #999;
  padding: .05rem .15rem;
}

.p-foot {
  padding: .15rem;
  margin-top: .15rem;
  border-top: 1px solid #f0f2f5;
  display: flex;
  justify-content: flex-end;
}

.opt-title {
  padding: .05rem;
  font-weight: bold;
}

.opt-btn {
  margin: 0 .1rem .1rem 0;
  width: .9rem;
}

.empty {
  width: 100%;
}
</style>